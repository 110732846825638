import React, { Fragment } from "react"
import { graphql } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"
import styled from "@emotion/styled"
import { GatsbyImage } from "gatsby-plugin-image"

import Footer from "../../components/Layout/Footer"
import SeoComp from "../../components/SeoComp"

const OurStory = ({ intl, data }) =>
  data &&
  data.allDirectusOurStoryTranslation.nodes.length > 0 && (
    <Fragment>
      <SeoComp
        description={
          data.allDirectusSeoTranslation.nodes[0] &&
          data.allDirectusSeoTranslation.nodes[0].meta_description
        }
        title={
          data.allDirectusSeoTranslation.nodes[0] &&
          data.allDirectusSeoTranslation.nodes[0].meta_title
        }
        twitter_card={
          data.allDirectusSeoTranslation.nodes[0] &&
          data.allDirectusSeoTranslation.nodes[0].twitter_card
        }
        image={
          data.allDirectusSeoTranslation.nodes[0] &&
          data.allDirectusSeoTranslation.nodes[0].image &&
          data.allDirectusSeoTranslation.nodes[0].image.data &&
          data.allDirectusSeoTranslation.nodes[0].image.data.url
        }
        hreflangPathname={{
          fr: "notre-histoire",
          en: "our-story",
          de: "our-story",
          es: "our-story",
        }}
      />
      {data.allDirectusOurStoryTranslation.nodes[0].title && (
        <Title
          dangerouslySetInnerHTML={{
            __html: data.allDirectusOurStoryTranslation.nodes[0].title,
          }}
        />
      )}
      {data.allDirectusOurStoryTranslation.nodes[0].subtitle && (
        <SubTitle
          dangerouslySetInnerHTML={{
            __html: data.allDirectusOurStoryTranslation.nodes[0].subtitle,
          }}
        />
      )}
      {data.allDirectusOurStoryTranslation.nodes[0].subtitle && <Divider />}
      <RowWrapper direction="column">
        {data.allDirectusOurStoryTranslation.nodes[0].desktop_banner_image &&
          data.allDirectusOurStoryTranslation.nodes[0].desktop_banner_image.data
            .url && (
            <DesktopBannerImage
              objectFit="contain"
              // src={
              //   data.allDirectusOurStoryTranslation.nodes[0]
              //     .desktop_banner_image.data.url
              // }
              image={
                data.allDirectusOurStoryTranslation.nodes[0]
                  .desktop_banner_image.localFile.childImageSharp
                  .gatsbyImageData
              }
            />
          )}
        {data.allDirectusOurStoryTranslation.nodes[0].mobile_banner_image &&
          data.allDirectusOurStoryTranslation.nodes[0].mobile_banner_image.data
            .url && (
            <MobileBannerImage
              objectFit="contain"
              //  src={
              //    data.allDirectusOurStoryTranslation.nodes[0]
              //      .mobile_banner_image.data.url
              //  }
              image={
                data.allDirectusOurStoryTranslation.nodes[0].mobile_banner_image
                  .localFile.childImageSharp.gatsbyImageData
              }
            />
          )}
      </RowWrapper>
      <Container>
        {data.allDirectusOurStoryTranslation.nodes[0].page_content.list &&
          data.allDirectusOurStoryTranslation.nodes[0].page_content.list.map(
            (item, index) => {
              return (
                <RowWrapper key={index.toString()}>
                  {item.text && (
                    <ColumnWrapper>
                      {item.text.map((text_item, text_index) => {
                        return (
                          <TextBox
                            key={text_index.toString()}
                            dangerouslySetInnerHTML={{
                              __html: text_item,
                            }}
                          />
                        )
                      })}
                    </ColumnWrapper>
                  )}
                  {item.image && (
                    <ImageWrapper>
                      <Image className="lazyload" data-src={item.image} />
                    </ImageWrapper>
                  )}
                </RowWrapper>
              )
            }
          )}
      </Container>

      {data.allDirectusFooterTranslation.nodes.length > 0 && (
        <Footer data={data.allDirectusFooterTranslation.nodes[0]} />
      )}
    </Fragment>
  )

const Container = styled.div`
  display: flex;
  flex-direction: ${props => (props.direction ? props.direction : "column")};
  align-items: ${props => (props.align ? props.align : "center")};
  justify-content: ${props => (props.justify ? props.justify : "center")};
  max-width: 1405px;
  width: 100%;
  margin: 0px auto;
  padding-top: 20px;

  > div:nth-of-type(3) {
    flex-direction: row-reverse;

    @media (max-width: 850px) {
      flex-direction: column-reverse;
    }
  }

  > div:nth-of-type(4) {
    @media (max-width: 850px) {
      flex-direction: column-reverse;
    }
  }
`

const RowWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  @media (max-width: 550px) {
    flex-direction: column;
  }
`

const ColumnWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0px 15px 30px;
  flex: 1;
  flex-basis: 58.33333%;

  @media (max-width: 850px) {
    max-width: 100%;
    flex-basis: 100%;
  }
`

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 15px 30px;
  flex: 1;
  max-width: 41.66667%;
  flex-basis: 41.66667%;

  @media (max-width: 850px) {
    max-width: 100%;
    flex-basis: 100%;
  }
`

const Title = styled.h1`
  color: #000;
  text-align: center;
  font-weight: bold;
  line-height: 1.2;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-size: 2em;
  font-family: "BebasNeueBold";
  margin-bottom: 0.5em;
  padding: 20px 20px 0px;
`

const SubTitle = styled.h2`
  color: #000;
  text-align: center;
  position: relative;
  font-family: "Museo";
  font-size: 1em;
  line-height: 1.4em;
  width: 100%;
  font-weight: bold;
  margin-bottom: 0px;
  padding: 0px 20px;
`

const Divider = styled.div`
  height: 2px;
  background-color: #000;
  margin: 1em auto;
  width: 100%;
  max-width: 250px;
`

const DesktopBannerImage = styled(GatsbyImage)`
  width: 100%;
  margin: 0px;

  @media (max-width: 850px) {
    display: none;
  }
`

const MobileBannerImage = styled(GatsbyImage)`
  width: 100%;
  margin: 0px;
  display: none;

  @media (max-width: 850px) {
    display: block;
  }
`

const Image = styled.img`
  display: block;
  max-width: 100%;
  max-height: 100%;
  margin: 0px;
`

const TextBox = styled.div`
  color: #000;
  font-size: 14px;
  line-height: 20px;
  text-align: justify;
  margin: 0px 0px 10px;
  width: 100%;

  > div {
    background-color: #ededec;
    padding: 15px;
  }

  :last-of-type {
    margin: 0px;
  }
`

export const query = graphql`
  query($lang: String!) {
    site {
      siteMetadata {
        title
      }
    }
    allDirectusOurStoryTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        title
        subtitle
        desktop_banner_image {
          data {
            url
          }
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1500, quality: 100)
            }
          }
        }
        mobile_banner_image {
          data {
            url
          }
          localFile {
            childImageSharp {
              gatsbyImageData(width: 850, quality: 100)
            }
          }
        }
        page_content {
          list {
            image
            text
          }
        }
      }
    }
    allDirectusFooterTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        facebook
        gbp_facebook
        youtube
        twitter
        instagram
        linkedin
        about_us {
          name
          list {
            link
            list
          }
        }
        contact {
          name
          list {
            link
            list
          }
          button {
            link
            list
          }
        }
        purchases {
          list {
            list
            link
          }
          name
        }
        help {
          list {
            link
            list
          }
          name
        }
        about_us_menu {
          name
          list {
            link
            list
          }
        }
        knowledge_center_menu {
          name
          list {
            link
            list
          }
        }
        shop_menu {
          name
          list {
            link
            list
          }
        }
        support_menu {
          name
          list {
            link
            list
          }
          button {
            link
            list
          }
        }
        newsletter_heading
        follow_us_heading
        footer_bottom {
          list {
            link
            list
          }
        }
      }
    }
    allDirectusSeoTranslation(
      filter: {
        language: { eq: $lang }
        seo: { page_name: { eq: "notre_histoire" } }
      }
    ) {
      nodes {
        meta_title
        meta_description
        twitter_card
        twitter_title
        url
        image {
          data {
            url
          }
        }
      }
    }
  }
`

export default injectIntl(OurStory)
